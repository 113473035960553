.rwd002-container {
  @extend .row;
  @extend .gx-5;
  @extend .gx-lg-6;
  margin-left: auto;
  margin-right: auto;
  margin-top: map-get($spacers, 6);
  max-width: calc(1280px + var(--bs-gutter-x) * 2 );
  @include media-up(xl) {
    flex-direction: row-reverse;
    padding-left: calc( var(--bs-gutter-x) * .5 );
    padding-right: calc( var(--bs-gutter-x) * .5 );
  }
  @include media-down(xl) {
    display: block;
  }
  @include media-down(lg) {
    margin-top: 0;
  }
}
.rwd002-main,
.rwd002-side {
  @extend .col;
  @include media-down(xl) {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%;
  }
  @include media-down(xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.rwd002-side {
  @include media-up(xl) {
    max-width: calc( 300px + var(--bs-gutter-x ) );
  }
}

.rwd002-main,
.rwd002-side {
  .rwd002-block-concept,
  .rwd002-block-contact,
  .rwd002-block-banner,
  .rwd002-block-freearea,
  .rwd002-block-content {
    @include media-up(xl) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .rwd002-list-content{
    @include media-up(xl) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.rwd002-main .rwd002-block:first-child,
.rwd002-side .rwd002-block:first-child{
  .rwd002-block-concept,
  .rwd002-block-contact,
  .rwd002-block-banner,
  .rwd002-block-freearea,
  .rwd002-block-content {
    @include media-up(xl) {
      margin-top: 0;
    }
  }
  &:empty {
    @include media-up(xl) {
      margin-bottom: -1 * map-get($spacers, 6);
    }
  }
}
.rwd002-main .rwd002-block:last-child,
.rwd002-side .rwd002-block:last-child{
  .rwd002-block-concept,
  .rwd002-block-contact,
  .rwd002-block-banner,
  .rwd002-block-freearea,
  .rwd002-block-content {
    @include media-up(xl) {
      margin-bottom: 0;
    }
  }
}

.rwd002-main .rwd002-list-sortlink{
  margin-bottom: 0;
  &:first-child{
    margin-top: 0;
  }
}