.rwd002-mainImg {
  display: flex;
  justify-content: center;
  margin-bottom: map-get($spacers, 6);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 4);
  }
  &__inner {
    img {
      max-width: 100%;
      height: auto;
    }
    > img[width="100%"] {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      max-height: calc(100vh - 120px);
      @include media-down(md) {
        max-height: calc(100vh - 55px);
      }
    }
  }
}

.mainvisual {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 46px 28px 34px;
  background-color: #6EBFC9;
  @include media-up(lg) {
    padding: 100px 28px 84px;
  }
  @include media-down(lg) {
    margin-bottom: 48px;
  }
  &__title {
    margin: 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}