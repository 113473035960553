.is--home {
  .rwd002-block.is-foot {

  }
}

.block-menu {
  @include media-up(lg) {
    max-width: calc(900px + 56px);
    margin: 0 auto 146px;
    padding: 0 28px;
  }
  @include media-up(xl) {
    max-width: 100%;
    padding: 0;
  }

  @include media-down(md) {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }

  &__title {
    margin-bottom: 2.125rem;
    font-weight: $font-weight-bold;
    font-size: 1.25rem;
    letter-spacing: .1em;
    @include media-down(lg) {
      font-size: $font-size-base;
      text-align: center;
      text-indent: 0.1em;
    }
  }

  &__list {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, auto);
    grid-gap: 13px 10px;
    margin-bottom: 36px;
    @include media-up(md) {
      grid-gap: 23px 20px;
      grid-template-columns: repeat(3, auto);
      margin-bottom: 67px;
    }
  }

  &__row {
    display: flex;
    justify-content: center;
    gap: 21px;
    @include media-down(lg) {
      gap: 19px;
      align-items: center;
      flex-direction: column;
    }
    &-item {
      @include hoverOpacity(.6);
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.block-menu-item {
  @include hoverOpacity(.6);
  img {
    max-width: 100%;
    height: auto;
  }
}